import { ErrorHandler } from "@business-finland/wif-ui-lib";

import buildGetStaticProps from "../service/static-props-api";

export const getStaticProps = buildGetStaticProps("Error 404", "/404");

export default function Custom404() {
  return (
    <ErrorHandler
      title={"Oops! Something's missing"}
      description={
        "The page you're looking for is currently unavailable. Try hitting refresh or returning to the homepage."
      }
      videoSrc={"/static/videos/Icon_Robot.mp4"}
      fallbackSrc={"/static/images/Icon_Robot.gif"}
    />
  );
}
